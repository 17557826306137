export const AUTH_LOGOUT_REASON_KEY = 'auth-logout-reason';
export const AUTH_LOGOUT_REASONS = {
  INACTIVITY: 'inactivity',
};

export const AUTH_ORIGINAL_URL_KEY = 'auth-originalUrl';

export const LOGIN_MODE = {
  UsernamePassword: 'UsernamePassword',
  ICNUsernamePassword: 'ICNUsernamePassword',
  SSOAndICNUsernamePassword: 'SSOAndICNUsernamePassword',
  SSO: 'SSO',
  ICNSSO: 'ICNSSO',
  SSOAndUsernamePassword: 'SSOAndUsernamePassword',
  Embedded: 'Embedded',
  EmbeddedAndUsernamePassword: 'EmbeddedAndUsernamePassword',
  ClientCredentials: 'ClientCredentials',
};

export const LANDING_PAGE = {
  ICN: 'ICN',
  SIMON: 'SIMON',
  Unified: 'Unified',
};

export const USER_TYPE = {
  Human: 'Human',
  System: 'System',
  Preview: 'Preview',
};

export const userTypeOptions = [
  {
    label: 'Human',
    value: USER_TYPE.Human,
  },
  {
    label: 'System',
    value: USER_TYPE.System,
  },
  {
    label: 'Preview',
    value: USER_TYPE.Preview,
  },
];

export const LOGIN_MODE_TO_LABEL = {
  [LOGIN_MODE.UsernamePassword]: 'Username-Password',
  [LOGIN_MODE.SSO]: 'SSO',
  [LOGIN_MODE.SSOAndUsernamePassword]: 'SSO and Username-Password',
  [LOGIN_MODE.Embedded]: 'Embedded',
  [LOGIN_MODE.EmbeddedAndUsernamePassword]: 'Embedded and Username-Password',
  [LOGIN_MODE.ClientCredentials]: 'Client Credentials',
  [LOGIN_MODE.ICNUsernamePassword]: 'iCapital Username-Password',
  [LOGIN_MODE.SSOAndICNUsernamePassword]: 'iCapital SSO and Username-Password',
};

export const loginModeOptions = Object.keys(LOGIN_MODE_TO_LABEL).map(
  loginMode => ({ label: LOGIN_MODE_TO_LABEL[loginMode], value: loginMode })
);

export const landingPageOptions = [
  { label: 'ICN', value: LANDING_PAGE.ICN },
  { label: 'SIMON', value: LANDING_PAGE.SIMON },
  { label: 'Unified', value: LANDING_PAGE.Unified },
];

export const loginModeUserTypes = {
  [LOGIN_MODE.ClientCredentials]: [USER_TYPE.System],
};

export const EVENT = {
  Initialized: 'Initialized',
  MasterUserFetchSuccess: 'MasterUserFetchSuccess',
  MasterUserFetchError: 'MasterUserFetchError',
  LoginSuccess: 'LoginSuccess',
  LoginRedirect: 'LoginRedirect',
  LoginError: 'LoginError',
  TransactionResume: 'TransactionResume',
  RecoveryTokenVerifySuccess: 'RecoveryTokenVerifySuccess',
  RecoveryTokenVerifyError: 'RecoveryTokenVerifyError',
  ExpiredPasswordChangeSuccess: 'ExpiredPasswordChangeSuccess',
  ExpiredPasswordChangeError: 'ExpiredPasswordChangeError',
  SecurityAnswerSuccess: 'SecurityAnswerSuccess',
  SecurityAnswerError: 'SecurityAnswerError',
  ResetPasswordRequestSuccess: 'ResetPasswordRequestSuccess',
  ResetPasswordRequestError: 'ResetPasswordRequestError',
  ResetPasswordChangeSuccess: 'ResetPasswordChangeSuccess',
  ResetPasswordChangeError: 'ResetPasswordChangeError',
  CrossTabMsg: 'CrossTabMsg',
  MFAEnrollSuccess: 'MFAEnrollSuccess',
  MFAEnrollError: 'MFAEnrollError',
  MFARequiredSuccess: 'MFARequiredSuccess',
  VerificationCodeSuccess: 'VerificationCodeSuccess',
  VerificationCodeError: 'VerificationCodeError',
};

export const TRANSACTION_STATUS = {
  SUCCESS: 'SUCCESS',
  RECOVERY: 'RECOVERY',
  PASSWORD_RESET: 'PASSWORD_RESET',
  PASSWORD_EXPIRED: 'PASSWORD_EXPIRED',
  MFA_REQUIRED: 'MFA_REQUIRED',
  MFA_CHALLENGE: 'MFA_CHALLENGE',
  MFA_ENROLL: 'MFA_ENROLL',
  MFA_ENROLL_ACTIVATE: 'MFA_ENROLL_ACTIVATE',
};

export const FACTOR_TYPE = {
  SMS: 'sms',
  CALL: 'call',
  PUSH: 'push',
  QUESTION: 'question',
  TOTP: 'token:software:totp',
};

export const COUNTRY_CODES = [
  {
    abbreviation: 'US',
    code: 1,
  },
  {
    abbreviation: 'AU',
    code: 61,
  },
  {
    abbreviation: 'BG',
    code: 359,
  },
  {
    abbreviation: 'CA',
    code: 1,
  },
  {
    abbreviation: 'DE',
    code: 49,
  },
  {
    abbreviation: 'GR',
    code: 30,
  },
  {
    abbreviation: 'IT',
    code: 39,
  },
  {
    abbreviation: 'JP',
    code: 81,
  },
  {
    abbreviation: 'MX',
    code: 52,
  },
  {
    abbreviation: 'NZ',
    code: 64,
  },
  {
    abbreviation: 'RO',
    code: 40,
  },
];

export const SELECT_OPTION_TYPES = {
  regular: 'regular',
  sms: 'sms',
  call: 'call',
};
