// Polyfills should be the very first JS code.
// They have side effects that the rest of the app depends on, including CSS (style-loader).
import './polyfills';
import './integrations/init';
import './globals';

// Global CSS, order matters.
import '@simon/core/styles/antd.less';
import '../style/_global.scss';
import '../style/common.scss';
import '../style/print.scss';
import '../style/fonts/roboto.scss';

import React, { lazy, Suspense, useEffect } from 'react';
import {
  createHashRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Routes,
} from 'react-router-dom';
import {
  useAuth,
  AuthProvider,
  EVENT,
  CatchOriginalRoute,
  useRestoreOriginalUrl,
  usePostMessage,
  authService,
} from '@simon/auth';
import Spinner from '@simon/ui/Spinner';
import { postMessage } from '@simon/core/utils/postMessage';
import { useQueryParam } from '@simon/core/hooks/useQueryParam';
import { captureException } from '@simon/core/sentry';
import { PRODUCT_LINES } from '@simon/core/sentry/constants';
import { trackEvent } from '@simon/core/utils/mixpanelSchemav2';
import { ENV, isUnifiedPlatform } from '@simon/config/env';
import { logout, logoutIcn } from './auth/service';

const AuthPage = React.lazy(() =>
  import('@simon/auth').then(module => ({ default: module.AuthPage }))
);
const AuthPageUnified = lazy(() => import('./auth/AuthPageUnified'));
const AppAuthorized = React.lazy(() => import('./app/App'));

function App() {
  const auth = useAuth();
  const { isAuthenticated, isSwitchingUser, isLoading } = auth;
  const [waitAuth] = useQueryParam('waitAuth');

  // Restore original URL from before being authorized
  useRestoreOriginalUrl();

  // Handle auth messages via postMessage
  usePostMessage();

  // Signalize to iframe parent that app is ready.
  useEffect(() => {
    postMessage({ command: 'ready' });
  }, []);

  const isImpersonationLoading = authService.getIsImpersonationLoading();

  if (
    isLoading ||
    ((waitAuth.toLowerCase() === 'true' || isImpersonationLoading) &&
      !isAuthenticated)
  ) {
    return (
      <Routes>
        <Route
          path="*"
          element={
            <CatchOriginalRoute>
              <Spinner isMainLoader />
            </CatchOriginalRoute>
          }
        />
      </Routes>
    );
  }

  // Order of routes is important
  // First should come the /auth pages because AppAuthorized has a fallback route that will block coming back to /auth once authenticated
  return (
    <Routes>
      {/* Auth stack for non-authorized users. For switching networks there is another customized instance in AppAuthorized. */}
      {!isAuthenticated && !isSwitchingUser && (
        <Route path="/auth/*" element={<AuthPage />} />
      )}
      {/* Lazy load AppAuthorized only when user is authenticated or switching network */}
      <Route
        path="*"
        element={
          isAuthenticated || isSwitchingUser ? (
            <AppAuthorized />
          ) : (
            <CatchOriginalRoute />
          )
        }
      />
    </Routes>
  );
}

const router = createHashRouter(
  createRoutesFromElements(
    <Route
      path="*"
      element={
        <Routes>
          <Route path="auth-unified/*" element={<AuthPageUnified />} />
          <Route
            path="*"
            element={
              <AuthProvider
                env={ENV}
                config={{
                  devMode: process.env.REACT_APP_DEBUG_AUTH === 'true',
                }}
                onEvent={(e, payload) => {
                  // Sentry
                  if (e === EVENT.LoginError) {
                    const { error } = payload;

                    // Ignore some expected errors.
                    // https://developer.okta.com/docs/reference/error-codes/
                    if (
                      ['login_required', 'E0000004'].includes(error.errorCode)
                    )
                      return;

                    captureException(error, {
                      tags: {
                        productLine: PRODUCT_LINES.CORE,
                        feature: 'auth_provider',
                      },
                    });
                  }

                  // Mixpanel
                  const name = name => `[Auth] ${name}`;
                  if (e === EVENT.LoginRedirect) {
                    const { url } = payload;
                    trackEvent(name(e), {
                      url,
                    });
                  } else if (e === EVENT.MFAEnrollSuccess) {
                    const { provider, factorType } = payload;
                    trackEvent(name(e), {
                      provider,
                      factorType,
                    });
                  } else if (
                    [
                      EVENT.ResetPasswordRequestSuccess,
                      EVENT.ResetPasswordChangeSuccess,
                    ].includes(e)
                  ) {
                    trackEvent(name(e), {});
                  }
                }}
                onBeforeGetToken={async () => {
                  // Clear previous SimSSO cookie before diving into the new session.
                  // This is to fix an inconsistency issue with SSO users using simultaneously an old session cookie + new auth token.
                  await logout();
                }}
                onLogout={async ({ isSwitch }) => {
                  await logout();
                  window.mixpanel.reset();
                  postMessage({
                    command: 'logout-done',
                  });

                  if (isUnifiedPlatform) {
                    await logoutIcn();
                  } else {
                    // hard refresh the page after logout so that UI state is reset
                    // if it's a network switch, give time for the new token to come in :/
                    // todo instead: abort requests, cancel pollings and reset the store without hard refresh
                    setTimeout(
                      () =>
                        window.location.assign(
                          `${window.location.origin}${window.location.pathname}`
                        ),
                      isSwitch ? 4000 : 0
                    );
                  }
                }}
              >
                <App />
              </AuthProvider>
            }
          />
        </Routes>
      }
    />
  )
);

export default function AppContainer() {
  return (
    <Suspense fallback={<Spinner isMainLoader />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}
